@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.description {
  display: flex;
  font: normal;
  font-size: large;
  position: relative;
  .body {
    max-width: 1200px;
    display: flex;
    gap: 50px;
    position: relative;
    p {
      margin: 0px;
      &:nth-of-type(1) {
        font-size: 40px; /* Default font size for the first p element */
        gap: 8px;
        line-height: 1.3;
        span {
          margin-right: 2.5px;
          font-family: Outfit;
          line-height: 50px;
          font-style: normal;
          font-size: 25px; /* Default font size for span */
        }
        .mask {
          position: relative;
          overflow: hidden;
          display: inline-flex;
        }
      }
      &:nth-of-type(2) {
        width: 60%;
        font-weight: 300;
      }
    }
  }
}
@media (min-width: 374px) {
  .description .body p:nth-of-type(1) {
    font-size: 40px; 
    line-height: 1;
  }
  .description .body p:nth-of-type(1) span {
    font-size: 20px; 
  }
}
@media (min-width: 768px) {
  .description .body p:nth-of-type(1) {
    font-size: 50px; 
    line-height: 1.3;
  }
  .description .body p:nth-of-type(1) span {
    font-size: 40px; 
  }
}
@media (min-width: 992px) {
  .description .body p:nth-of-type(1) {
    font-size: 55px; 
    line-height: 1.3;
  }
  .description .body p:nth-of-type(1) span {
    font-size: 35px; 
  }
}
@media (min-width: 1200px) {
  .description .body p:nth-of-type(1) {
    font-size: 60px; 
    line-height: 1.3;
  }
  .description .body p:nth-of-type(1) span {
    font-size: 40px; 
  }
}
