.iphone-container {
  position: relative;
  width: 100%; /* Fit the container width */
  max-width: 500px; /* Adjust based on your desired iPhone size */
}

.iphone-container svg {
  width: 100%;
  height: auto; /* Ensure SVG scales dynamically */
  display: block;
}

.video-container_iphone {
  position: absolute;
  top: 3%; /* Adjust proportionally to fit the iPhone screen's top position */
  left: 19%; /* Adjust proportionally to fit the iPhone screen's left position */
  width: 62%; /* Adjust to match the iPhone screen width */
  height: 95%; /* Adjust to match the iPhone screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow if video doesn't fit */
  z-index: 1; /* Bring video above the SVG */
}

.iphone-video {
  width: 100%; /* Match the width of the video container */
  height: 100%; /* Match the height of the video container */
  object-fit: cover; /* Ensures video covers the entire area */
  border-radius: 30px; /* Optional: Round video edges */

  z-index: 2; /* Ensure video is above all other elements */
}
