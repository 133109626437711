.device {
  position: relative;
  width: auto;
  height: 100%;
}
.video-container {
  position: absolute;
  top: 2.1%;
  left: 10.7%;
  width: 158%;
  height: 109%;
  overflow: hidden;
}

.video-container video {
  width: 50%;
  height: 80%;
  object-fit: cover;
  border-top-left-radius: 9px;
          border-top-right-radius: 9px; 
}

.detail-container {
  perspective: 1000px; /* Adjust for more or less 3D effect */
}

.d-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: hidden;
}

.parallax-layer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1200px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  transform: translate(-50%, -50%); /* Center the image */
  will-change: transform; /* Optimize for performance */
}
.highlight-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; /* Darker color for contrast */
}

.highlight-description {
  font-size: 1rem;
  color: #666; /* Subtle grey color for description */
}

.highlight-text:hover {
  background-color: rgba(255, 255, 255, 1); /* Stronger background on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
  transform: translateY(-5px); /* Lift effect on hover */
}
.start-aligned {
  width: 100%; /* Makes the text block span the full width */
  text-align: left; /* Aligns text to the left */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensures the content is aligned at the start */
  margin-left: 0; /* Moves text to the left of the page */
  padding-left: 20px; /* Optional: Adds padding to give a little space from the left edge */
}