@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Telma";
  src: url("../src/font/Telma_Complete/Fonts/Variable/Telma-Variable.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* this is noto */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Outfit:wght@100..900&display=swap');


.before::before,
.after::after {
  content: '';
  display: block;
  height: 1px;
  width: 40%;
  margin: auto;
  background-color: white;
  position: relative;
  transition: transform 0.3s;
}

.after::after {
  top: -5px;
}

.before::before {
  top: 5px;
}

.rotate-45 {
  transform: rotate(45deg);
}

.translate-y-1 {
  transform: translateY(-1px);
}
