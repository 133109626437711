.slidingImages {
  display: flex;
  flex-direction: column;
  gap: 3vw;
  position: relative;
  margin-top: 100px;
  background-color: white;
  z-index: 1;
  margin-bottom: 10vh;
  .slider {
    display: flex;
    position: relative;
    gap: 3vw;
    width: 120vw;
    left: -10vw;
    .project {
      width: 25%;
      height: 20vw;
      display: flex;
      align-items: center;
      justify-content: center;
      .imageContainer {
        position: relative;
        width: 80%;
        height: 70%;
        img {
          object-fit: cover;
        }
      }
    }
  }
}
