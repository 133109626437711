.hoverText:hover {
  font-weight: 900;
  color: rgb(238, gre242en, 255);
}
.hoverText {
  transition: 0.35s font-weight, 0.35s color;
}
.hoverText:hover + .hoverText {
  font-weight: 500;
  color: rgb(238, gre242en, 255);
}
.hoverText:hover + .hoverText + .hoverText {
  font-weight: 300;
  color: rgb(238, gre242en, 255);
}
.hoverText:hover + .hoverText + .hoverText + .hoverText {
  font-weight: 100;
  color: rgb(238, gre242en, 255);
}
.hoverText:has(+ .hoverText:hover) {
  font-weight: 200;
  color: rgb(238, gre242en, 255);
}
