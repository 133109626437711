.image-container {
  display: none; /* Default hidden */
  justify-content: center;
  align-items: center;
  width: 40%; /* Full width of the viewport */
  height: 50%; /* Full height of the viewport */
  overflow: hidden;
}

.image-container img {
  width: 70%; /* Cover the container width */
  height: 70%; /* Cover the container height */
  object-fit: cover; /* Maintain image proportions */
}

/* Show the container on larger screens */
@media (min-width: 768px) {
  .image-container {
    display: flex; /* Display on larger screens */
  }
}
