.introduction {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    background-color: #141516;

    svg {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100% + 300px);
        path {
            fill: #141516;
        }
    }

    p {
        display: flex;
        align-items: center;
        color: white;
        font-size: 42px;
        position: absolute;
        z-index: 1;
        margin: 15px; // Reset default margin

        span {
            display: block;
            width: 10px;
            height: 10px;
            background-color: white;
            border-radius: 50%;
            margin-right: 15px; // Space between the dot and the text
        }
    }

    @media (max-width: 768px) {
        height: 100%; // Use 100% height to adjust for mobile browser UI
        svg {
            height: 100%; // Adjust SVG height to fit the smaller screen
        }

        p {
            font-size: 28px; // Decrease font size for mobile screens
        }
    }
}
